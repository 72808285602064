import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Redirect, useLocation } from 'react-router-dom';

class SessionTimeout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false, // Controls modal visibility
      timer: null, // Stores inactivity timer
      countdown: 60, // Countdown timer in seconds (1 min)
      countdownTimer: null, // Timer for countdown inside modal
      displayLogin: false,
    };
  }

  componentDidMount() {
    this.resetTimer();
    window.addEventListener("mousemove", this.resetTimer);
    window.addEventListener("keypress", this.resetTimer);
  }

  componentWillUnmount() {
    this.clearTimer();
    this.clearCountdown();
    window.removeEventListener("mousemove", this.resetTimer);
    window.removeEventListener("keypress", this.resetTimer);
  }

  // Function to reset inactivity timer
  resetTimer = () => {
    // Check if test mode
    let location = window.location.search;
    const query = new URLSearchParams(location);
    const sessionQuery = query.get('session');
    const sessionTimeInterval = sessionQuery === "true" ? (10 * 1000) : (5 * 60 * 1000); // 10 seconds or 5 minutes inactivity

    this.clearTimer();
    const newTimer = setTimeout(() => {
        console.log("trigger timeout");
        let sessionPersonId = localStorage.getItem("personId");
        if(sessionPersonId){
            this.setState({ showModal: true, countdown: 60 }, () => {
                this.startCountdown();
            });
        }else{
            this.stayLoggedIn();
        }
    }, sessionTimeInterval); // 5 minutes inactivity
    this.setState({ timer: newTimer });
  };

  // Function to start countdown timer inside modal
  startCountdown = () => {
    this.clearCountdown();
    const countdownTimer = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.countdown <= 1) {
          clearInterval(countdownTimer);
          this.handleSessionTimeout(); // Log out user or take action
        }
        return { countdown: prevState.countdown - 1 };
      });
    }, 1000);
    this.setState({ countdownTimer });
  };

  // Function to clear inactivity timer
  clearTimer = () => {
    if (this.state.timer) {
      clearTimeout(this.state.timer);
    }
  };

  // Function to clear countdown timer
  clearCountdown = () => {
    if (this.state.countdownTimer) {
      clearInterval(this.state.countdownTimer);
    }
  };

  // Function to handle "Stay Logged In" action
  stayLoggedIn = () => {
    this.setState({ showModal: false, countdown: 60 });
    this.clearCountdown();
    this.resetTimer();
  };

  // Function to handle session timeout (Logout user or redirect)
  handleSessionTimeout = () => {
    localStorage.clear();
    this.setState({ showModal: false, countdown: 60, displayLogin: true });
    this.clearCountdown();
    this.resetTimer();
    setTimeout(() => {
        this.setState({ displayLogin: false });
    }, 1000);
    // Implement logout logic here, e.g., redirect to login page
  };

  render() {
    if(this.state.displayLogin) {
        return <Redirect to="/" />
    }

    return (
      <div>
        <Modal
          show={this.state.showModal}
          onHide={this.stayLoggedIn}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p className="font-weight-bold text-center">Are you still there?</p>
            <p className="text-center">
              It seems you have been inactive for the past 5 minutes. The
              application will automatically log you out of your current session when the timer runs out.
            </p>
            <h4 className="text-center mt-4">{this.state.countdown}</h4>
            <div className="w-100 mb-4 d-flex flex-row justify-content-center">
              <button
                type="button"
                className="btn btn-success font-bold"
                onClick={this.stayLoggedIn}
              >
                I'm Still Here!
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default SessionTimeout;