import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

class RecaptchaModal extends Component {
    render() { 
      const { isShowModal, showHideModal, onRecaptchaChange, onRecaptchaErrorExpired } = this.props; // Extract the prop
        return ( 
            <React.Fragment>
                <Modal
                    id="popup-message"
                    show={isShowModal}
                    onHide={showHideModal}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    <h5 className="mb-0">Please Verify</h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <center>
                            <ReCAPTCHA
                              sitekey="6Lf9X-wqAAAAAC7X_v2Gl-73QG519AfRZp_G8XiN"
                              onChange={onRecaptchaChange}
                              onErrored={onRecaptchaErrorExpired}
                              onExpired={onRecaptchaErrorExpired}
                            />
                            </center>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

export default (RecaptchaModal);