import React, { Component } from "react";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Modal } from 'react-bootstrap';
import axios from "axios";
import {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  generateFpHash,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import EditAccount from "../components/editAccount";
import RecaptchaModal from '../components/recaptchaModal';
import {
  Table,
  Form,
  DropdownButton,
  Dropdown,
  Popover,
  OverlayTrigger,
  Spinner,
  Tabs,
  Tab,
} from "react-bootstrap";
import $ from "jquery";
import _ from "lodash";
import Loading from "./loading";
import LogoutButton from "./logoutButton";
import ModalViewChart from "./modalViewChart";
import ModalMyBills from "./modalMyBills";
import ModalNotificationSettings from "./modalNotificationSettings";
import ModalPaymentHistory from "./modalPaymentHistory";
import PayNowModal from "./paynowModal";
import ModalPaperlessBilling from "./modalPaperlessBilling";
// import formatNum from 'format-num'
import { Redirect } from "react-router-dom";
import NumberFormat from "react-number-format";
import { paArrList, getPaDetails } from "../actions/paymentArrangementActions";

class MultipleAccView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccDetails: {
        accountId: "",
        personId: "",
        fullName: "",
        addressLine1: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        homePhone: "",
        mobilePhone: "",
        workPhone: "",
        emailAddress: "",
      },
      paymentFormData: {
        accountId: "",
        x_login: "",
        x_fp_sequence: "",
        x_fp_timestamp: "",
        x_amount: "",
        x_fp_hash: "",
        x_invoice_num: "",
        x_po_num: "",
        x_show_form: "PAYMENT_FORM",
        x_relay_response: "TRUE",
        Checkout: "Pay Now",
      },
      activeTab: "postpaid",
      paymentProcessing: false,
      overlayShow: false,
      overlayTarget: null,
      selectedAccountId: "",
      selectedPremiseAddress: "",
      subtotal: 0,
      accountSummary: [],
      isCheckedAll: false,
      isEdit: false,
      isLoading: true,
      viewPrepaid: false,
      viewPostpaid: true,
      showAccountTypeTabs: false,
      displayLogin: false,
      displayPA: false,
      showPAPendButton: false,
      showPAButton: false,
      eligiblePAaccountSummary: [],
      isPALoading: true,
      isCashOnlyFlag: false,
      isCashOnlyFlagLoaded: false,
      cashOnlyAccounts: [],
      isCaptchaValid: false,
      isShowModal: false,
    };
  }

  // Function to execute when reCAPTCHA is solved
  onRecaptchaChange = (token) => {
    console.log("Captcha solved! Token:", token);
    if (token) {
      this.setState({
        ...this.state,
        isCaptchaValid: true,
      });
    } else {
      this.setState({
        ...this.state,
        isCaptchaValid: false,
      });
    }
  };

  onRecaptchaErrorExpired = () => {
    this.setState({
      ...this.state,
      isCaptchaValid: false,
    })
  }

  componentDidMount() {
    if (
      localStorage.getItem("prepaid") === "true" &&
      localStorage.getItem("postpaid") === "true"
    ) {
      this.setState(
        {
          showAccountTypeTabs: true,
        },
        () => {
          this.fetchPostpaidAccount();
        }
      );
    } else if (localStorage.getItem("prepaid") === "true") {
      this.fetchPrepaidAccount();
    } else if (localStorage.getItem("postpaid") === "true") {
      this.fetchPostpaidAccount();
    } else {
      this.setState({
        displayLogin: true,
      });
    }
    this.startTimer();
  }
  startTimer = () => {
    let timer = setInterval(() => {
      // console.log("loading!!!!!!");
      let sortedAccountID = [];
      let sortedAccountSummary = [];
      for (
        let count = 0;
        count < this.props.dashboard.orderData.accountSummary.length;
        count++
      ) {
        sortedAccountID.push(
          this.props.dashboard.orderData.accountSummary[count].accID
        );
      }
      for (let count = 0; count < sortedAccountID.sort().length; count++) {
        for (
          let count1 = 0;
          count1 < this.props.dashboard.orderData.accountSummary.length;
          count1++
        ) {
          if (
            sortedAccountID.sort()[count] ===
            this.props.dashboard.orderData.accountSummary[count1].accID
          ) {
            sortedAccountSummary.push(
              this.props.dashboard.orderData.accountSummary[count1]
            );
            break;
          }
        }
      }
      this.setState({
        accountSummary: sortedAccountSummary,
      });
      if (
        this.state.userAccDetails.accountId.length ===
        this.state.accountSummary.length
      ) {
        clearInterval(timer);
        this.getPAStatus();
        
        // Validate account IDs if Cash Only
        // const newUrl = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;
        // if (this.state.isCashOnlyFlagLoaded) {
        //   //
        // } else if (newUrl.endsWith("/")) {
        //     this.checkCashOnlyFlag(newUrl + "api/v1/check-cash-only");
        // } else {
        //     this.checkCashOnlyFlag(newUrl + "/api/v1/check-cash-only");
        // }

        const accLists = this.state.accountSummary;
        const hasCashOnlyFlag = accLists.some((item) => item.cashOnlyFlag === "Y");
        if(hasCashOnlyFlag && !this.state.isCashOnlyFlag){
          this.setState({
            isCashOnlyFlag: true
          });
        }
      }
    }, 1000);
  };
  getLocalData = (keys) => {
    let sessionAccountId, sessionPersonId;
    let lsAccountId = localStorage.getItem(keys.accountId);
    let lsAccountIds = localStorage.getItem(keys.accountIds);
    const accountType =
      keys.accountId == "prepaidAccountId" ||
      keys.accountId == "prepaidAccountIds"
        ? "prepaid"
        : "postpaid";
    if (
      !(lsAccountId === "" || lsAccountId === null || lsAccountId === undefined)
    ) {
      var accountId = [];
      var arrAccountId = lsAccountId.split(",");
      accountId.push([arrAccountId[0], arrAccountId[1], arrAccountId[2]]);
      sessionAccountId = accountId;
    } else if (
      !(
        lsAccountIds === "" ||
        lsAccountIds === null ||
        lsAccountIds === undefined
      )
    ) {
      var storageAccIds = [],
        premiseAddresses = [],
        currPaidAmounts = [],
        accountIds = [];
      var arrAccountIds = lsAccountIds.split(",");
      let flag = 0;
      for (var count = 0; count < arrAccountIds.length; count++) {
        if (flag === 0) {
          storageAccIds.push(arrAccountIds[count]);
          flag = flag + 1;
        } else if (flag === 1) {
          premiseAddresses.push(arrAccountIds[count]);
          flag = flag + 1;
        } else if (flag === 2) {
          currPaidAmounts.push(arrAccountIds[count]);
          flag = 0;
        }
      }
      for (var count = 0; count < storageAccIds.length; count++) {
        accountIds.push([
          storageAccIds[count],
          premiseAddresses[count],
          currPaidAmounts[count],
        ]);
      }

      sessionAccountId = accountIds;
    } else {
      sessionAccountId = [];
    }
    sessionPersonId = localStorage.getItem("personId");
    this.setState(
      {
        ...this.state,
        selectedAccountId: this.props.dashboard.selectedAccountId,
        selectedPremiseAddress: this.props.dashboard.selectedPremAdd,
        userAccDetails: {
          ...this.state.userAccDetails,
          accountId: sessionAccountId,
          personId: sessionPersonId,
        },
      },
      () => {
        const accountId = this.state.selectedAccountId;
        const personId = sessionPersonId;
        this.executeRequests(
          sessionAccountId,
          accountId,
          personId,
          keys.accountType
        );
      }
    );
  };
  fetchPrepaidAccount = () => {
    this.setState({
      paEnabled: false,
    });
    let keys = {
      accountId: "prepaidAccountId",
      accountIds: "prepaidAccountIds",
      accountType: "prepaid",
    };
    this.startTimer();
    this.getLocalData(keys);
  };
  fetchPostpaidAccount = () => {
    // if(localStorage.getItem('isPaymentArrangement') === 'false'){
    //     this.setState({
    //         paEnabled: true,
    //     })
    // }
    // else{
    //     this.setState({
    //         paEnabled: false,
    //     })
    // }
    let keys = {
      accountId: "postpaidAccountId",
      accountIds: "postpaidAccountIds",
      accountType: "postpaid",
    };
    this.startTimer();
    this.getLocalData(keys);
  };

  /////////////////////////////////////
  // Handle Cash 
  // checkCashOnlyFlag = (newUrl) => {
  //   const accountList = this.state.accountSummary;
  //   const accountIdsList = accountList.map((e) => e.accID ?? "N/A");
  //   return axios
  //       .post(
  //         newUrl,
  //         {
  //           accountIds: accountIdsList
  //         },
  //         {
  //           headers: { 'Content-Type': 'application/json' }
  //       })
  //       .then(response => {
  //         console.log("Fetched data for cash-only-flag");
  //         // Update Cash Only Flag
  //         if (response.data) {
  //           this.setState({
  //             //
  //           });
  //         } else {
  //           this.setState({
  //             //
  //           });
  //         }
  //       })
  //       .catch(err => {
  //         console.log("Failed to fetch api cash-only-flag", err);
  //         this.setState({
  //           isCashOnlyFlagLoaded: true,
  //           // isCashOnlyFlag: true
  //         })
  //       })
  // }

  async executeRequests(sessionAccountId, accountId, personId, accountType) {
    try {
      this.props.saveAccountId(accountId);
      let [result1, result2] = await Promise.all([
        this.props.fetchMultipleLatestBill(sessionAccountId, accountType),
        this.props.fetchMultipleAddOpptyRequest(
          accountId,
          personId,
          accountType
        ),
      ]);
      if (result1 && result2.dataFetched) {
        const userAccountDetails = this.props.dashboard.userAccountDetails;
        let sortedAccountID = [];
        let sortedAccountSummary = [];

        for (
          let count = 0;
          count < this.props.dashboard.orderData.accountSummary.length;
          count++
        ) {
          sortedAccountID.push(
            this.props.dashboard.orderData.accountSummary[count].accID
          );
        }

        for (let count = 0; count < sortedAccountID.sort().length; count++) {
          for (
            let count1 = 0;
            count1 < this.props.dashboard.orderData.accountSummary.length;
            count1++
          ) {
            if (
              sortedAccountID.sort()[count] ===
              this.props.dashboard.orderData.accountSummary[count1].accID
            ) {
              sortedAccountSummary.push(
                this.props.dashboard.orderData.accountSummary[count1]
              );
              break;
            }
          }
        }

        this.setState({
          ...this.state,
          accountSummary: this.props.dashboard.orderData.accountSummary,
          subtotal: this.props.dashboard.orderData.subTotal,
          userAccDetails: {
            ...this.state.userAccDetails,
            fullName: userAccountDetails.fullName,
            addressLine1: userAccountDetails.addressLine1,
            city: userAccountDetails.city,
            state: userAccountDetails.state,
            postal: userAccountDetails.postal,
            country: userAccountDetails.country,
            homePhone: userAccountDetails.homePhone,
            mobilePhone: userAccountDetails.mobilePhone,
            workPhone: userAccountDetails.workPhone,
            emailAddress: userAccountDetails.emailAddress,
          },
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      this.props.showMessage(true, "Server Error. Try again later!");
    }
  }
  showEdit = (e) => {
    e.preventDefault();
    this.setState({ isEdit: true });
  };
  formatAmount = (value) => {
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    return (value = parseFloat(Math.round(value * 100) / 100).toFixed(2));
  };
  amountToBePaidOnChange = (e) => {
    let id = e.target.id,
      value = e.target.value;
    value = this.formatAmount(value);
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if ("amount-input-" + arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    arrAccountSummary[selectedIndex].amountToBePaid = value;
    this.setState({
      ...this.state,
      accountSummary: arrAccountSummary,
    });
  };
  selectRow = (e) => {
    const id = e.target.id,
      value = e.target.value;
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "",
      isCheckedAll = false;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if ("amount-input-" + arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    console.log("selectedIndex", selectedIndex);
    if (arrAccountSummary[selectedIndex].checked === false) {
      arrAccountSummary[selectedIndex].checked = true;
    }

    this.setState({
      ...this.state,
      isCheckedAll: isCheckedAll,
      accountSummary: arrAccountSummary,
    });
  };
  unselectRow = (e) => {
    const id = e.target.id,
      value = e.target.value;
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "",
      isCheckedAll = false;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if ("amount-input-" + arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    console.log("selectedIndex", selectedIndex);
    if (
      arrAccountSummary[selectedIndex].checked === true &&
      value === "$ 0.00"
    ) {
      isCheckedAll = false;
      arrAccountSummary[selectedIndex].checked = false;
    }

    this.setState({
      ...this.state,
      isCheckedAll: isCheckedAll,
      accountSummary: arrAccountSummary,
    });
  };
  tabOnSelect(key) {
    if (key === "postpaid") {
      this.setState(
        {
          activeTab: "postpaid",
        },
        () => {
          this.fetchPostpaidAccount();
        }
      );
    } else {
      this.setState(
        {
          activeTab: "prepaid",
        },
        () => {
          this.fetchPrepaidAccount();
        }
      );
    }
  }
  payOnClick = (item) => {
    console.log(item)
    if (item.amountToBePaid != "" || item.amountToBePaid != 0) {
      this.setState(
        {
          paymentProcessing: true,
        },
        () => {
          this.props.generateFpHash(item).then((response) => {
            // console.log("response",response)
            this.setState(
              {
                ...this.state,
                paymentFormData: {
                  ...this.state.paymentFormData,
                  accountId: item.accID,
                  x_login: response.login,
                  x_fp_sequence: response.fp_sequence,
                  x_fp_timestamp: response.fp_timestamp,
                  x_amount: item.amountToBePaid,
                  x_fp_hash: response.fp_hash,
                  x_invoice_num: response.fp_sequence,
                  x_po_num: item.fullName,
                  x_show_form: "PAYMENT_FORM",
                  x_relay_response: "TRUE",
                  Checkout: "Pay Now",
                },
              },
              () => {
                document.getElementById("payment-form-" + item.accID).submit();
                console.log(
                  "this.state.paymentFormData",
                  JSON.stringify(this.state.paymentFormData)
                );
              }
            );
          });
        }
      );
    } else {
      this.props.showMessage(true, "Please enter an amount!");
    }
  };
  
  recaptchaButton = (item, type) => {
    return (
      <form>
        <input
          type="button"
          name="Checkout"
          style={{ margin: "0" }}
          value={type}
          className={`btn ${item.cashOnlyFlag === "Y" ? "btn-disabled" : "btn-primary mav-pay-btn"} font-bold`}
          onClick={() => this.setState({
            ...this.state,
            isShowModal: !this.state.isShowModal
          })}
          disabled={this.state.paymentProcessing || item.cashOnlyFlag === "Y"}
        />
      </form>
    );
  };

  paymentForm = (item, type) => {
      
      console.log("This is the payment item", item);
      return (
      <form
        id={"payment-form-" + item.accID}
        action={
          process.env.REACT_APP_ENVIRONEMENT === "production"
            ? "https://checkout.globalgatewaye4.firstdata.com/payment"
            : "https://demo.globalgatewaye4.firstdata.com/payment"
        }
        method="post"
      >
        <input
          name="x_login"
          value={this.state.paymentFormData.x_login}
          type="hidden"
        />
        <input
          name="x_fp_sequence"
          value={this.state.paymentFormData.x_fp_sequence}
          type="hidden"
        />
        <input
          name="x_fp_timestamp"
          value={this.state.paymentFormData.x_fp_timestamp}
          type="hidden"
        />
        <input
          name="x_amount"
          value={this.state.paymentFormData.x_amount}
          type="hidden"
        />
        <input
          name="x_fp_hash"
          value={this.state.paymentFormData.x_fp_hash}
          type="hidden"
        />
        <input
          name="x_invoice_num"
          value={this.state.paymentFormData.x_invoice_num}
          type="hidden"
        />
        <input
          name="x_po_num"
          value={this.state.paymentFormData.x_po_num}
          type="hidden"
        />
        <input
          name="x_show_form"
          value={this.state.paymentFormData.x_show_form}
          type="hidden"
        />
        <input
          name="x_relay_response"
          value={this.state.paymentFormData.x_relay_response}
          type="hidden"
        />
        <input
          type="button"
          name="Checkout"
          style={{ margin: "0" }}
          value={type}
          className={`btn ${item.cashOnlyFlag === "Y" ? "btn-disabled" : "btn-primary mav-pay-btn"} font-bold`}
          onClick={() => {

            if(!(1<=item.amountToBePaid &&item.amountToBePaid<=99999.99 )){
              this.props.showMessage(true, "Please enter a valid minimum amount of $1.00!");
              return;
            }
            this.payOnClick(item);
          }}
          disabled={this.state.paymentProcessing || item.cashOnlyFlag === "Y"}
        />
      </form>
    );
  };
  renderDueDate = (item) => {
    try {
      if (item.dueDate.match(new RegExp("\\bundefined\\b")) != null) {
        return item.dueDate.substr(0, 10);
      }
      return item.dueDate;
    } catch (ex) {
      return null;
    }
  };
  showAccountTypeTabs = () => {
    return (
      <Tabs
        defaultActiveKey="postpaid"
        id="account-type-tab"
        onSelect={this.tabOnSelect.bind(this)}
      >
        <Tab
          eventKey="postpaid"
          title="Postpaid Accounts"
          className="font-bold dark-font-color"
        >
          <div className="account-summary-label">
            <label className="light-font-color font-bold">
              Your Account Summary{" "}
              <span className="ml-2">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </span>{" "}
            </label>
          </div>
          <div
            id="scrollable-container"
            className={
              this.state.accountSummary.length > 5 ? "table-overflow" : ""
            }
          >
            <Table id="account-numbers" className="" bordered hover>
              <React.Fragment>
                <thead>
                  <tr>
                    <th>Account ID</th>
                    <th>Service Location</th>
                    <th>Due Date</th>
                    <th>Amount</th>
                    <th style={{ backgroundColor: "#c3c1c1", zIndex: "1" }}>
                      Amount to be Paid
                    </th>
                    <th>Pay</th>
                    <th style={{ zIndex: "1" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!(
                    this.state.accountSummary.length ===
                    this.state.userAccDetails.accountId.length
                  ) ? (
                    <tr>
                      <td colSpan="12">
                        <center>
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                          />
                          {" Loading... "}
                        </center>
                      </td>
                    </tr>
                  ) : (
                    _.map(this.state.accountSummary, (item, index) => {
                      return (
                        <tr key={index} className={""}>
                          <td>
                            <div className="account-number">{item.accID}</div>
                          </td>
                          <td>
                            <div className="service-location">
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={
                                  <Popover id="popover-contained">
                                    {item.serviceLocation}
                                  </Popover>
                                }
                              >
                                <p className="m-0">
                                  {item.serviceLocation.substr(0, 9) + "..."}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>
                          <td>
                            <div
                              className="due-date"
                              style={
                                item.isDueDateRed > 0 && item.amount > 0
                                  ? { color: "red", fontWeight: "600" }
                                  : {}
                              }
                            >
                              {item.dueDate === "Due Now" ? (
                                <OverlayTrigger
                                  trigger="hover"
                                  placement="left"
                                  overlay={
                                    <Popover id="popover-contained">
                                      The amount of{" "}
                                      <strong>
                                        $ {parseFloat(item.arrearsTotal)}
                                      </strong>{" "}
                                      is necessary to avoid disconnection.
                                    </Popover>
                                  }
                                >
                                  <p className="m-0">
                                    {item.dueDate + " "}
                                    <span
                                      style={{
                                        color: "black",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <i
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                      ></i>
                                    </span>
                                  </p>
                                </OverlayTrigger>
                              ) : (
                                <React.Fragment>
                                  {this.renderDueDate(item)}
                                </React.Fragment>
                              )}
                            </div>
                          </td>
                          <td className="text-right">${" " + item.amount}</td>
                          <td>
                            <div className="amount-to-pay row">
                              <NumberFormat
                                isAllowed={(values) => {
                                  const {floatValue} = values;
                                  return floatValue <= 99999.99;
                                }}
                                id={"amount-input-" + item.accID}
                                className="text-right mx-auto"
                                value={
                                  !item.checked
                                    ? ""
                                    : parseFloat(item.amountToBePaid)
                                }
                                // value={parseFloat(item.amountToBePaid)}
                                allowEmptyFormatting={true}
                                thousandSeparator={true}
                                prefix={"$ "}
                                onChange={this.amountToBePaidOnChange.bind(
                                  this
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onFocus={this.selectRow.bind(this)}
                                onBlur={this.unselectRow.bind(this)}
                              />
                            </div>
                          </td>
                          <td>
                            {
                              this.state.isCaptchaValid
                              ? this.paymentForm(item, "Pay Now")
                              : this.recaptchaButton(item, "Verfiy")
                            }
                          </td>
                          <td>
                            <DropdownButton
                              id="dropdown-item-button"
                              title="Select an Action"
                              className="actions-dropdown"
                            >
                              <Dropdown.Item as="button">
                                <ModalMyBills accId={item.accID} />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalViewChart
                                  accId={item.accID}
                                  accountType={"postpaid"}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalPaymentHistory accId={item.accID} />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalPaperlessBilling accId={item.accID} />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalNotificationSettings accId={item.accID} />
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </React.Fragment>
            </Table>
          </div>
        </Tab>
        <Tab eventKey="prepaid" title="Prepaid Accounts">
          <div className="account-summary-label">
            <label className="light-font-color font-bold">
              Your Account Summary{" "}
              <span className="ml-2">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </span>{" "}
            </label>
          </div>
          <div
            id="scrollable-container"
            className={
              this.state.accountSummary.length > 5 ? "table-overflow" : ""
            }
          >
            <React.Fragment>
              <Table id="account-numbers" className="" bordered hover>
                <thead>
                  <tr>
                    <th>Account ID</th>
                    <th>Service Location</th>
                    {/* <th>Debt Recovery</th> */}
                    <th>Electric Balance</th>
                    <th style={{ backgroundColor: "#c3c1c1", zIndex: "1" }}>
                      Amount to be Paid
                    </th>
                    <th>Reload</th>
                    <th style={{ zIndex: "1" }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!(
                    this.state.accountSummary.length ===
                    this.state.userAccDetails.accountId.length
                  ) ? (
                    <tr>
                      <td colSpan="12">
                        <center>
                          <Spinner
                            animation="border"
                            size="sm"
                            variant="primary"
                          />
                          {" Loading... "}
                        </center>
                      </td>
                    </tr>
                  ) : (
                    _.map(this.state.accountSummary, (item, index) => {
                      return (
                        <tr key={index} className={""}>
                          <td>
                            <div className="account-number">{item.accID}</div>
                          </td>
                          <td>
                            <div className="service-location">
                              <OverlayTrigger
                                trigger="hover"
                                placement="top"
                                overlay={
                                  <Popover id="popover-contained">
                                    {item.serviceLocation}
                                  </Popover>
                                }
                              >
                                <p className="m-0">
                                  {item.serviceLocation.substr(0, 9) + "..."}
                                </p>
                              </OverlayTrigger>
                            </div>
                          </td>
                          {/* <td className="text-right">
                                                        ${" "+item.debtRecovery}
                                                    </td> */}
                          <td className="text-right">
                            ${" " + item.electricBalance}
                          </td>
                          <td>
                            <div className="amount-to-pay row">
                              <NumberFormat
                                isAllowed={(values) => {
                                  const {floatValue} = values;
                                  return floatValue <= 99999.99;
                                }}
                                id={"amount-input-" + item.accID}
                                className="text-right mx-auto"
                                value={
                                  !item.checked
                                    ? ""
                                    : parseFloat(item.amountToBePaid)
                                }
                                // value={parseFloat(item.amountToBePaid)}
                                thousandSeparator={true}
                                prefix={"$ "}
                                onChange={this.amountToBePaidOnChange.bind(
                                  this
                                )}
                                allowEmptyFormatting={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                allowNegative={false}
                                onFocus={this.selectRow.bind(this)}
                                onBlur={this.unselectRow.bind(this)}
                              />
                            </div>
                          </td>
                          <td>{this.paymentForm(item, "Reload")}</td>
                          <td>
                            <DropdownButton
                              id="dropdown-item-button"
                              title="Select an Action"
                              className="actions-dropdown"
                            >
                              <Dropdown.Item as="button">
                                <ModalMyBills
                                  accId={item.accID}
                                  mode={"prepaid"}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalViewChart
                                  accId={item.accID}
                                  accountType={"prepaid"}
                                  prepaySAId={item.prepaySAId}
                                />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalPaymentHistory accId={item.accID} />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalPaperlessBilling accId={item.accID} />
                              </Dropdown.Item>
                              <Dropdown.Item as="button">
                                <ModalNotificationSettings accId={item.accID} />
                              </Dropdown.Item>
                            </DropdownButton>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </Table>
            </React.Fragment>
          </div>
        </Tab>
      </Tabs>
    );
  };
  showPostpaidAccountsTable = () => {
    return (
      <React.Fragment>
        <div className="account-summary-label">
          <label className="light-font-color font-bold">
            Your Account Summary{" "}
            <span className="ml-2">
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>{" "}
          </label>
        </div>
        <div
          id="scrollable-container"
          className={
            this.state.accountSummary.length > 5 ? "table-overflow" : ""
          }
        >
          <Table id="account-numbers" className="" bordered hover>
            <React.Fragment>
              <thead>
                <tr>
                  <th>Account ID</th>
                  <th>Service Location</th>
                  <th>Due Date</th>
                  <th>Amount</th>
                  <th style={{ backgroundColor: "#c3c1c1", zIndex: "1" }}>
                    Amount to be Paid
                  </th>
                  <th>Pay</th>
                  <th style={{ zIndex: "1" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!(
                  this.state.accountSummary.length ===
                  this.state.userAccDetails.accountId.length
                ) ? (
                  <tr>
                    <td colSpan="12">
                      <center>
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="primary"
                        />
                        {" Loading... "}
                      </center>
                    </td>
                  </tr>
                ) : this.state.accountSummary.length === 0 ? (
                  this.changeSelectedTab()
                ) : (
                  _.map(this.state.accountSummary, (item, index) => {
                    return (
                      <tr key={index} className={""}>
                        <td>
                          <div className="account-number">{item.accID}</div>
                        </td>
                        <td>
                          <div className="service-location">
                            <OverlayTrigger
                              trigger="hover"
                              placement="top"
                              overlay={
                                <Popover id="popover-contained">
                                  {item.serviceLocation}
                                </Popover>
                              }
                            >
                              <p className="m-0">
                                {item.serviceLocation.substr(0, 9) + "..."}
                              </p>
                            </OverlayTrigger>
                          </div>
                        </td>
                        <td>
                          <div
                            className="due-date"
                            style={
                              item.isDueDateRed > 0 && item.amount > 0
                                ? { color: "red", fontWeight: "600" }
                                : {}
                            }
                          >
                            {item.dueDate === "Due Now" ? (
                              <OverlayTrigger
                                trigger="hover"
                                placement="left"
                                overlay={
                                  <Popover id="popover-contained">
                                    The amount of{" "}
                                    <strong>$ {item.arrearsTotal}</strong> is
                                    necessary to avoid disconnection.
                                  </Popover>
                                }
                              >
                                <p className="m-0">
                                  {item.dueDate + " "}
                                  <span
                                    style={{ color: "black", fontSize: "12px" }}
                                  >
                                    <i
                                      className="fa fa-question-circle"
                                      aria-hidden="true"
                                    ></i>
                                  </span>
                                </p>
                              </OverlayTrigger>
                            ) : (
                              <React.Fragment>
                                {item.dueDate.match(
                                  new RegExp("\\bundefined\\b")
                                ) != null
                                  ? item.dueDate.substr(0, 10)
                                  : item.dueDate}
                              </React.Fragment>
                            )}
                          </div>
                        </td>
                        <td className="text-right">${" " + item.amount}</td>
                        <td>
                          <div className="amount-to-pay row">
                            <NumberFormat
                              isAllowed={(values) => {
                                const {floatValue} = values;
                                return floatValue <= 99999.99;
                              }}
                              id={"amount-input-" + item.accID}
                              className="text-right mx-auto"
                              value={
                                !item.checked
                                  ? ""
                                  : parseFloat(item.amountToBePaid)
                              }
                              // value={parseFloat(item.amountToBePaid)}
                              allowEmptyFormatting={true}
                              thousandSeparator={true}
                              prefix={"$ "}
                              onChange={this.amountToBePaidOnChange.bind(this)}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onFocus={this.selectRow.bind(this)}
                              onBlur={this.unselectRow.bind(this)}
                            />
                          </div>
                        </td>
                        <td>
                          {
                            this.state.isCaptchaValid
                            ? this.paymentForm(item, "Pay Now")
                            : this.recaptchaButton(item, "Verfiy")
                          }
                        </td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button"
                            title="Select an Action"
                            className="actions-dropdown"
                          >
                            <Dropdown.Item as="button">
                              <ModalMyBills accId={item.accID} />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalViewChart
                                accId={item.accID}
                                accountType={"postpaid"}
                              />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalPaymentHistory accId={item.accID} />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalPaperlessBilling accId={item.accID} />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalNotificationSettings accId={item.accID} />
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </React.Fragment>
          </Table>
        </div>
      </React.Fragment>
    );
  };
  showPrepaidAccountsTable = () => {
    return (
      <React.Fragment>
        <div className="account-summary-label">
          <label className="light-font-color font-bold">
            Your Account Summary{" "}
            <span className="ml-2">
              <i class="fa fa-refresh" aria-hidden="true"></i>
            </span>{" "}
          </label>
        </div>
        <div
          id="scrollable-container"
          className={
            this.state.accountSummary.length > 5 ? "table-overflow" : ""
          }
        >
          <React.Fragment>
            <Table id="account-numbers" className="" bordered hover>
              <thead>
                <tr>
                  <th>Account ID</th>
                  <th>Service Location</th>
                  {/* <th>Debt Recovery</th> */}
                  <th>Electric Balance</th>
                  <th style={{ backgroundColor: "#c3c1c1", zIndex: "1" }}>
                    Amount to be Paid
                  </th>
                  <th>Reload</th>
                  <th style={{ zIndex: "1" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {!(
                  this.state.accountSummary.length ===
                  this.state.userAccDetails.accountId.length
                ) ? (
                  <tr>
                    <td colSpan="12">
                      <center>
                        <Spinner
                          animation="border"
                          size="sm"
                          variant="primary"
                        />
                        {" Loading... "}
                      </center>
                    </td>
                  </tr>
                ) : (
                  _.map(this.state.accountSummary, (item, index) => {
                    return (
                      <tr key={index} className={""}>
                        <td>
                          <div className="account-number">{item.accID}</div>
                        </td>
                        <td>
                          <div className="service-location">
                            <OverlayTrigger
                              trigger="hover"
                              placement="top"
                              overlay={
                                <Popover id="popover-contained">
                                  {item.serviceLocation}
                                </Popover>
                              }
                            >
                              <p className="m-0">
                                {item.serviceLocation.substr(0, 9) + "..."}
                              </p>
                            </OverlayTrigger>
                          </div>
                        </td>
                        {/* <td className="text-right">
                                                    ${" "+item.debtRecovery}
                                                </td> */}
                        <td className="text-right">
                          ${" " + item.electricBalance}
                        </td>
                        <td>
                          <div className="amount-to-pay row">
                            <NumberFormat
                              isAllowed={(values) => {
                                const {floatValue} = values;
                                return floatValue <= 99999.99;
                              }}
                              id={"amount-input-" + item.accID}
                              className="text-right mx-auto"
                              value={
                                !item.checked
                                  ? ""
                                  : parseFloat(item.amountToBePaid)
                              }
                              allowEmptyFormatting={true}
                              // value={parseFloat(item.amountToBePaid)}
                              thousandSeparator={true}
                              prefix={"$ "}
                              onChange={this.amountToBePaidOnChange.bind(this)}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              onFocus={this.selectRow.bind(this)}
                              onBlur={this.unselectRow.bind(this)}
                            />
                          </div>
                        </td>
                        <td>
                          {
                            this.state.isCaptchaValid
                            ? this.paymentForm(item, "Reload")
                            : this.recaptchaButton(item, "Verfiy")
                          }
                        </td>
                        <td>
                          <DropdownButton
                            id="dropdown-item-button"
                            title="Select an Action"
                            className="actions-dropdown"
                          >
                            <Dropdown.Item as="button">
                              <ModalMyBills
                                accId={item.accID}
                                mode={"prepaid"}
                              />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalViewChart
                                accId={item.accID}
                                accountType={"prepaid"}
                                prepaySAId={item.prepaySAId}
                              />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalPaymentHistory accId={item.accID} />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalPaperlessBilling accId={item.accID} />
                            </Dropdown.Item>
                            <Dropdown.Item as="button">
                              <ModalNotificationSettings accId={item.accID} />
                            </Dropdown.Item>
                          </DropdownButton>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </Table>
          </React.Fragment>
        </div>
      </React.Fragment>
    );
  };
  isEligibleForPA = () => {
    const { accountSummary } = this.state;

    const residAccountIds = accountSummary
      .filter(({ className }) => className == "RESID")
      .map((item, index) => item);
    console.log("residAccountIds", residAccountIds);
    const accountIds = residAccountIds
      .filter(({ amount }) => amount > 0)
      .map(({ accID }) => accID);

    if (accountIds.length > 0) {
      return true;
    }
    return false;
  };
  getPAStatus = () => {
    this.props.getPaDetails().then((response) => {
      let caseData = response;
      let eligiblePAaccountSummary = [];
      const { accountSummary } = this.state;
      console.log("caseData", caseData);
      console.log("accountSummary", accountSummary);
      const pendingUploads = caseData.filter(
        ({ customerContactStatus }) => customerContactStatus == "10"
      );
      if (pendingUploads.length > 0) {
        this.setState({
          showPAPendButton: true,
          showPAButton: false,
          isPALoading: false,
        });
      } else {
        for (let count = 0; count < accountSummary.length; count++) {
          let addIndex = true;
          for (let count1 = 0; count1 < caseData.length; count1++) {
            if (accountSummary[count].accID == caseData[count1].accountId) {
              addIndex = false;
            }
          }
          if (addIndex) {
            if (accountSummary[count].className == "RESID") {
              eligiblePAaccountSummary.push(accountSummary[count]);
            }
          }
        }
        console.log(
          "eligiblePAaccountSummary",
          this.state.eligiblePAaccountSummary
        );
        if (eligiblePAaccountSummary.length > 0) {
          this.setState(
            {
              showPAPendButton: false,
              showPAButton: true,
              isPALoading: false,
              eligiblePAaccountSummary: eligiblePAaccountSummary,
            },
            () => {
              console.log(
                "eligiblePAaccountSummary",
                this.state.eligiblePAaccountSummary
              );
            }
          );
        } else {
          this.setState(
            {
              showPAPendButton: false,
              showPAButton: false,
              isPALoading: false,
              eligiblePAaccountSummary: eligiblePAaccountSummary,
            },
            () => {
              console.log(
                "eligiblePAaccountSummary",
                this.state.eligiblePAaccountSummary
              );
            }
          );
        }
      }
      // console.log("response pa details caseData",pendingUploads)
    });
  };
  paPendingOnClick = () => {
    const accountSummary = this.state.accountSummary;
    const customerName = this.state.userAccDetails.fullName;
    const emailAddress = this.state.userAccDetails.emailAddress;
    //get all account ids from redirect props and this will be used on next page
    const residAccountIds = accountSummary
      .filter(({ className }) => className == "RESID")
      .map((item, index) => item);
    console.log("residAccountIds", residAccountIds);
    const accountIds = residAccountIds
      .filter(({ amount }) => amount > 0)
      .map(({ accID }) => accID);
    this.setState({
      isFetchingPaPend: true,
    });
    this.props
      .paArrList(accountIds, customerName, emailAddress)
      .then((response) => {
        if (response) {
          this.setState(
            {
              paAccountsResponse: this.props.paymentArrangement.paAccounts,
              isFetchingPaPend: false,
            },
            () => {
              this.setState({
                displayCaseForm: true,
              });
            }
          );
        }
      })
      .catch((error) => {
        this.props.showMessage(
          true,
          "Error occured while processing your request for payment arrangement!"
        );
        this.setState({
          displayDashboard: true,
          isFetchingPaPend: false,
        });
      });
  };

  render() {
    var fullName = this.state.userAccDetails.fullName;
    var firstName = fullName.substr(0, fullName.indexOf(" "));
    var lastName = fullName.substr(fullName.indexOf(" ") + 1);
    if (fullName.includes(",")) {
      firstName = fullName.substr(fullName.indexOf(",") + 1);
      lastName = fullName.substr(0, fullName.indexOf(","));
    }
    const userFullname = this.state.userAccDetails.fullName;
    const userAccountId = this.state.userAccDetails.accountId;
    //const userFullname = lastName;
    const addressLine1 = this.state.userAccDetails.addressLine1;
    const city = this.state.userAccDetails.city;
    const state = this.state.userAccDetails.state;
    const postal = this.state.userAccDetails.postal;
    const country = this.state.userAccDetails.country;
    const homePhone = this.state.userAccDetails.homePhone;
    const mobilePhone = this.state.userAccDetails.mobilePhone;
    const workPhone = this.state.userAccDetails.workPhone;
    const emailAddress = this.state.userAccDetails.emailAddress;

    if (this.state.displayLogin) {
      return <Redirect to="/" />;
    }
    if (this.state.displayPA) {
      return (
        <Redirect
          to={{
            pathname: "/payment-arrangement",
            state: {
              accountSummary: this.state.eligiblePAaccountSummary,
              customerName: this.state.userAccDetails.fullName,
              emailAddress: this.state.userAccDetails.emailAddress,
            },
          }}
        />
      );
    }
    if (this.state.displayCaseForm) {
      return (
        <Redirect
          to={{
            pathname: "/payment-arrangement/case-form",
            state: {
              accountSummary: this.state.accountSummary,
              paAccountsResponse: this.state.paAccountsResponse,
              download: false,
            },
          }}
        />
      );
    }
    return this.state.isEdit === true ? (
      <EditAccount />
    ) : // this.state.isLoading || this.state.isPALoading ?
    this.state.isLoading ? (
      <Loading />
    ) : (
      <React.Fragment>
        <RecaptchaModal
          isShowModal={this.state.isShowModal}
          showHideModal={() => this.setState({
            ...this.state,
            isShowModal: !this.state.isShowModal
          })}
          onRecaptchaChange={this.onRecaptchaChange}
          onRecaptchaErrorExpired={this.onRecaptchaErrorExpired}
        />
        <div id="welcome-wrap">
          <div className="row pb-1">
            <div className="col-lg-5">
              <h3 className="primary-font-color">Hi {userFullname}!</h3>
            </div>
            <div className="col-lg-7">
              <div className="btn-logout">
                {this.state.showPAButton && this.isEligibleForPA() ? (
                  <button
                    className="btn btn-primary font-bold mav-pay-btn d-none"
                    style={{ width: "275px", padding: "10px" }}
                    onClick={() => {
                      this.setState({ displayPA: true });
                    }}
                  >
                    Request Payment Arrangement
                  </button>
                ) : this.state.showPAPendButton ? (
                  <button
                    onClick={() => {
                      this.paPendingOnClick();
                    }}
                    className="btn btn-primary font-bold"
                    style={{
                      backgroundColor: "#fdda6e",
                      borderColor: "#fdda6e",
                      width: "350px",
                      padding: "10px",
                      color: "black",
                      fontSize: "14px",
                    }}
                    disabled={this.state.isFetchingPaPend}
                  >
                    {this.state.isFetchingPaPend
                      ? "Please wait..."
                      : "Payment Arrangement Document Pending"}
                  </button>
                ) : null}
                {/* {
                                    this.state.paEnabled && (this.state.accountSummary.length === this.state.userAccDetails.accountId.length) && this.isEligibleForPA() && localStorage.getItem('isPAPending') == "false" ?
                                    <button className="btn btn-primary font-bold mav-pay-btn" style={{width: '275px', padding: '10px'}} onClick={() => {this.setState({displayPA: true})}} >
                                        Request Payment Arrangement
                                    </button>
                                    :
                                    (this.state.accountSummary.length === this.state.userAccDetails.accountId.length) && localStorage.getItem('isPAPending') == "true" ?
                                        <button 
                                            onClick={() => {this.paPendingOnClick()}} 
                                            className="btn btn-primary font-bold" 
                                            style={{backgroundColor: '#fdda6e', borderColor: '#fdda6e', width: '350px', padding: '10px', color: 'black', fontSize: '14px'}} 
                                            disabled={this.state.isFetchingPaPend}
                                        >
                                            {this.state.isFetchingPaPend ? 'Please wait...' : 'Payment Arrangement Document Pending'}
                                        </button>
                                    :
                                    null
                                } */}
                <LogoutButton />
              </div>
            </div>
          </div>
        </div>
        {
          this.state.isCashOnlyFlag
          ? <div className="">
              <h6 style={{color: "red"}}>NOTICE: Your account(s) has been flagged for Cash Only payments. Please contact the GPA Customer Service Division for further details.</h6>
            </div>
          : <div></div>
        }
        <div className="account-summary">
          <div className="row">
            <div className="col-lg-12">
              {this.state.showAccountTypeTabs
                ? this.showAccountTypeTabs()
                : !this.state.showAccountTypeTabs &&
                  localStorage.getItem("prepaid") === "true"
                ? this.showPrepaidAccountsTable()
                : !this.state.showAccountTypeTabs &&
                  localStorage.getItem("postpaid") === "true"
                ? this.showPostpaidAccountsTable()
                : null}
              <br />
              <br />
              <div id="info-wrap" className="border">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="account-info">
                      <div className="header">
                        <label className="secondary-font-color font-bold">
                          Account Information
                        </label>
                        <a
                          href="/"
                          className="primary-font-color"
                          onClick={this.showEdit}
                        >
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/edit-user.png"
                              }
                            />
                          </span>{" "}
                          Edit Account
                        </a>
                      </div>
                      <hr />
                      <div className="body">
                        <div className="row">
                          <div className="col-lg-4">
                            <p>
                              <strong>Customer Name</strong>
                            </p>
                            <p>{userFullname}</p>
                          </div>
                          {/* <div className="col-lg-3">
                                                        <p><strong>Mailing Address</strong></p>
                                                        <p>{addressLine1+' '+city+' '+state+' '+postal+' '+country}</p>
                                                    </div> */}
                          <div className="col-lg-4">
                            <p>
                              <strong>Phone Numbers</strong>
                            </p>
                            <p>Home: {homePhone}</p>
                            <p>Mobile: {mobilePhone}</p>
                            <p>Work: {workPhone}</p>
                          </div>
                          <div className="col-lg-4">
                            <p>
                              <strong>Email Address</strong>
                            </p>
                            <p>{emailAddress}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
  paymentArrangement: state.paymentArrangement,
});

export default connect(mapStateToProps, {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  generateFpHash,
  showMessage,
  paArrList,
  getPaDetails,
})(MultipleAccView);
